:root {
  --wc-main-primary: #03256C;
  --wc-main-secondary: #06BEE1;
}

.wc-primary {
  color: var(--wc-main-primary);
}

.wc-secondary {
  color: var(--wc-main-secondary);
}

.wc-bg-primary {
  background-color: var(--wc-main-primary);
}

.wc-bg-secondary {
  background-color: var(--wc-main-secondary);
}


body{
  font-family: 'Mulish', sans-serif;
}

.comfortaa-logo {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800 !important;
  color: var(--wc-main-secondary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px white inset !important;
   -webkit-text-fill-color: var(--wc-main-primary) !important;
}